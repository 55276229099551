@import '../../global/styles/lib';

#theme-footer {
	clear: both;
	margin-top: 4rem;

	.column {
		padding: 0;
	} // .column

	.name {
		font-family: $nocturne;

		.tagline {
			font-size: 1.25rem;
		}

		.llc {
			font-family: $montserrat;
			font-size: 0.85rem;
		}
	} // .name

	.row {
		align-items: center;
	} // .row

	a:not(.button) {
		color: inherit;
	}

	.social-icons {
		margin-bottom: 0.5rem;
		.icon {
			display: inline-block;
			width: 1.75rem;

			&:not(:last-of-type) {
				margin-right: 0.5rem;
			}

			> a {
				opacity: .7;
				transition: opacity $hover-transition;

				&:hover { opacity: 1; }
				> svg {
					color: inherit;
					fill: currentColor;
				}
			} // > a
		} // .icon
	} // .social-icons

	.footer-tagline {
		@include show-for(medium);
		font-size: 2rem;
		font-weight: bold;
	}

	.menu {
		@include inline-list;

		.active > a {
			background: none;
		} 
	} // .menu
	
	.menu-item {
		text-transform: capitalize;

		> a {
			display: inline-block;
			color: inherit;
			padding: 0;
		}
	} // .menu-item

	.site-logo {
		max-width: 30%;
	}

	.footer-left {
		order: 2;
		.name {
			margin-bottom: 0.5rem;
		}
		.address,
		.email-phone {
			margin-bottom: 0;
		}
	}
	.footer-right {
		order: 1;
		text-align: center;
	}

	.cd-credit {
		$text-offset: 100%;
		$transition-on: opacity .8s ease-in-out;
		$transition-off: opacity 3s ease-out;

		display: block;
		position: relative;
		width: 4rem;
		opacity: .4;
		transition: $transition-off;
		padding: 1rem;
		margin: 0 auto 0;

		&:before,
		&:after {
			display: block;
			position: absolute;
			top: 50%;
			opacity: 0;
			pointer-events: none;
			transform: translateY(-28%);
			transition: $transition-off;
			font-size: .75rem;
			font-weight: 900;
			letter-spacing: rem-calc(1);
			line-height: 1;
			white-space: nowrap;
			color: $primary-color;
		}
		&:before {
			right: $text-offset;
			content: 'POWERED BY';
		}
		&:after {
			left: $text-offset;
			content: 'CHATMAN DESIGN';
		}

		> svg {
			fill: $primary-color;
		}

		&:hover {
			opacity: 1;
			transition: $transition-on;

			&:before,
			&:after {
				opacity: 1;
				pointer-events: auto;
				transition: $transition-on;
			}
		}
	} // .cd-credit

	.copyright {
		margin-top: 0.5rem;
	} // .copyright

	@include breakpoint(small only) {
		text-align: center;

		.menu-item {
			display: block;
			text-align: center;
			padding: .5rem 0;
			border-bottom: rem-calc(1) solid rgba(#fff,.1);

			&:not(:last-child):after {
				display: none;
			}
		} // .menu-item
	} // small
	@include breakpoint(medium down) {
		h1, .h1 {
			font-size: 2rem;
		}
	}
	@include breakpoint(medium) {
		.footer-left {
			order: 1;
		} // .footer-left
		
		.footer-right {
			order: 2;
			text-align: right;
		} // .footer-right

		.copyright {
			margin-top: 0;
		} // .copyright
	}

} // #theme-footer