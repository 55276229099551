/**
 * Sass Mixins
 *
 * @package   seedyjoints
 * @since     1.0
 */

/**
 * Extend element's background to left and right edges of viewport.
 *
 * @since    3.0.0
 * @author   <corey@chatmandesign.com>
 *
 * @param string $position  Any valid CSS position value, or NULL/FALSE to not set
 *                          position; but note the element must create a positioning
 *                          context for the bleed effect to work. Default: relative.
 * @param string $direction Direction the bleed will extend in -- left, right, both.
 *                          Deafult: both.
 */
 @mixin bleed($position: relative, $direction: both) {
	@if $position {
		position: $position;
	}


	$commonSelector: "";
	$bleedLeft: false;
	$bleedRight: false;

	@if $direction == left {
		$commonSelector: "&:before";
		$bleedLeft: true;
	}
	@else if $direction == right {
		$commonSelector: "&:after";
		$bleedRight: true;
	}
	@else if $direction == both {
		$commonSelector: "&:before, &:after";
		$bleedLeft: true;
		$bleedRight: true;
	}
	@else {
		@error "Invalid $direction: #{$direction}";
	}

	#{$commonSelector} {
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100vw;
		background: inherit;
		content: " ";
		border: inherit;
	}
	@if $bleedLeft {
		&:before {
			right: 100%;
		}
	}
	@if $bleedRight {
		&:after {
			left: 100%;
		}
	}
} // bleed

/**
 * Lock (Fluid Type Mixin -- CD Remix)
 * Created 10/11/16 @ 02:19:30 PM
 *
 * Original from here:
 * http://madebymike.com.au/writing/precise-control-responsive-typography/
 *
 * This is a modified version of the above mixin that takes a single SCSS list
 * instead of two variables for min and max VW. This makes it easier to use with
 * the existing Foundation range variables in _settings.scss. You can of course
 * still pass a plain list e.g. "(20rem, 40rem)" as the argument for the range
 * parameter if you need a custom range.
 *
 * @param      $properties A space separated list of CSS properties
 * @param      $vw-range A SCSS list defining a viewport width range like
 *             "(20em, 40em)".
 * @param      $min-value A minimum property value in px/em/rem
 * @param      $max-value A maximum property value in px/em/rem
 * @author     Chatman Design / Dustin Paluch <dustin@chatmandesign.com>
 */
@mixin lock($properties, $width-range, $min-value, $max-value) {

	$min-vw: nth($width-range, 1);
	$max-vw: nth($width-range, 2);
	$range-units: unit($min-vw);

	@each $property in $properties {
		#{$property}: $min-value;
	}

	@media screen and (min-width: $min-vw) {
		@each $property in $properties {
			#{$property}: calc(#{$min-value}
							   + #{strip-unit($max-value - $min-value)}
							   * (100vw - #{$min-vw})
							   / #{strip-unit($max-vw - $min-vw)});
		}
	}

	@media screen and (min-width: $max-vw) {
		@each $property in $properties {
			#{$property}: $max-value;
		}
	}
}

/**
 * List items inline, separated by pipes by default.
 *
 * @since    3.0.0
 * @author   <corey@chatmandesign.com>
 */
@mixin inline-list($separator: '|', $selector: '> li', $spacing: .6em) {
	#{$selector} {
		display: inline-block;
		list-style: none;

		&:not(:last-child):after {
			display: inline-block;
			vertical-align: middle;
			content: '#{$separator}';
			margin: 0 #{$spacing};
		}
	}
}

@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

@function in-list($list, $var) {
	@return (false == index($list, $var));
}

@mixin gutter($sides, $type: margin, $multiple: 1) {
	@if ($type != margin) and ($type != padding) {
		@error "Invalid gutter type.";
	}

	@if ($sides == all) or (length($sides) == 4) {
		#{$type}: map-get($grid-column-gutter, 'small') * $multiple;

		@include breakpoint(medium) {
			#{$type}: map-get($grid-column-gutter, 'medium') * $multiple;
		}
	} // if
	@else {
		@each $side in $sides {
			#{$type}-#{$side}: map-get($grid-column-gutter, 'small') * $multiple;
		} // each side

		@include breakpoint(medium) {
			@each $side in $sides {
				#{$type}-#{$side}: map-get($grid-column-gutter, 'medium') * $multiple;
			} // each side
		}
	} // else
} // gutter()

//! – DEPRECATED

/**
 * Transition property with vendor prefixes (DEPRECATED)
 *
 * DEPRECATED now that we use autoprefixer. Just use the standard CSS property.
 *
 * I totally rewrote this to be cleaner and easier to use. You'll need to be using
 * Sass 3.2+ for these to work. Thanks to @anthonyshort for the inspiration on these.
 *
 * USAGE: @include transition(<property> <time> <easing>);
 */
@mixin transition($transition...) {
	@warn "Transition() mixin is deprecated now that we use autoprefixer. Please use standard CSS transition property.";

	// defining prefixes so we can use them in mixins below
	$prefixes: ("-webkit", "-ms", "-o",  "");

	@each $prefix in $prefixes {
		#{$prefix}-transition: $transition;
	}

	transition: $transition;
}

/**
 * CSS3 Gradients with vendor prefixes
 *
 * Be careful with these since they can really slow down your CSS. Don't overdo it.
 */

/* @include css-gradient(#dfdfdf,#f8f8f8); */
@mixin css-gradient($from: #dfdfdf, $to: #f8f8f8) {
	background-color: $to;
	background-image: linear-gradient(to bottom, $from, $to);
}
